import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { ShapeImages, WeightCalculatorData } from "./WeightCalculatorData";
import { useApplication } from "@ryerson/frontend.application";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { Dropdown, IncrementDecrementInput, Option } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { CalculatorHeroContent } from "@components/Calculators/Hero";
import stringMath from "string-math";

export type WeightCalculatorStaticContent = {
	product: string;
	shape: string;
	grade: string;
	calculate: string;
	reset: string;
	numberOfPieces: string;
	pieces: string;
	pounds: string;
	seeMatching: string;
	disclaimer: string;
	width: string;
	thickness: string;
	height: string;
	wall: string;
	length: string;
	outsidediameter: string;
	insidediameter: string;
	diameter: string;
};

export type WeightCalculatorProps = {
	heroContent: CalculatorHeroContent;
};

const StaticContent: LanguageContent<WeightCalculatorStaticContent> = {
	en: {
		product: "Product",
		shape: "Shape",
		grade: "Grade",
		calculate: "Calculate",
		reset: "Reset",
		numberOfPieces: "Number of Pieces",
		pieces: "pieces",
		pounds: "lbs",
		seeMatching: "See price/availability for matching products",
		disclaimer: "Calculated weights are for estimation purposes only.",
		width: "Width",
		thickness: "Thickness",
		height: "Height",
		wall: "Wall",
		length: "Length",
		outsidediameter: "Outside Diameter",
		insidediameter: "Inside Diameter",
		diameter: "Diameter",
	},
	fr: {
		product: "Produit",
		shape: "Forme",
		grade: "Grade",
		calculate: "Calculer",
		reset: "Réinitialiser",
		numberOfPieces: "Nombre de pièces",
		pieces: "pièces",
		pounds: "lbs",
		seeMatching: "Voir le prix et la disponibilité des produits correspondants",
		disclaimer: "Les poids calculés sont uniquement à des fins d’estimation.",
		width: "Largeur",
		thickness: "Épaisseur",
		height: "Hauteur",
		wall: "Mur",
		length: "Longueur",
		outsidediameter: "Diamètre extérieur",
		insidediameter: "Diamètre interieur",
		diameter: "Diamètre",
	},
	es: {
		product: "Producto",
		shape: "Forma",
		grade: "Grado",
		calculate: "Calcular",
		reset: "Restablecer",
		numberOfPieces: "Número de piezas",
		pieces: "piezas",
		pounds: "lbs",
		seeMatching: "Vea el precio/disponibilidad para productos relacionados",
		disclaimer: "Los pesos calculados son solo con fines de estimación.",
		width: "Ancho",
		thickness: "Espesor",
		height: "Altura",
		wall: "Muro",
		length: "Longitud",
		outsidediameter: "Diámetro exterior",
		insidediameter: "Diámetro interno",
		diameter: "Diámetro",
	},
};

const ImageContainer = styled.div`
	display: inline-block;
	width: 260px;
	vertical-align: top;
	height: 410px;
	background-position: center center;
	background-size: calc(100% - 40px) auto;
	background-repeat: no-repeat;
`;

const FormContainer = styled.div`
	display: inline-block;
	width: calc(100% - 260px);
	height: auto;
	padding: 40px;
	box-sizing: border-box;
	vertical-align: top;
	min-height: 410px;
	border-radius: 0 2px 2px 0;
`;

const CalculatorContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	border-radius: 2px;
`;

const FormRow = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 30px;
`;

const FormCell = styled.div`
	display: inline-block;
	width: calc(25% - 20px);
	max-width: 190px;
	min-width: 185px;
	margin-right: 20px;
	&:last-of-type {
		margin-right: 0px;
	}
`;

// const Divider = styled.hr`
// 	display: block;
// 	width: 100%;
// 	margin-top: 22px;
// 	margin-bottom: 22px;
// 	opacity: 0.1;
// `;

const MobileSpacer = styled.div`
	display: block;
	width: 100%;
	height: 15px;
`;

const MobileFormCell = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 20px;
	margin-right: 0px;
	min-width: 100%;
	max-width: 100%;
`;

const LabelIcon = styled.div`
	display: inline-block;
	margin-left: 10px;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	img {
		width: 100%;
		height: 100%;
		display: block;
	}
`;

const ResultsContainer = styled.div`
	display: block;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 35px;
	padding-left: 300px;
	padding-right: 40px;
	box-sizing: border-box;
`;

const ResultsLeft = styled.div`
	display: inline-block;
	width: 400px;
	vertical-align: middle;
`;

// const ResultsRight = styled.div`
// 	display: inline-block;
// 	width: calc(100% - 400px);
// 	vertical-align: middle;
// 	text-align: right;
// `;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 140px;
`;

const Span = styled.span``;

const EvaluateString = (string: any, parameters: any[]) => {
	for (let i = 0; i < parameters.length; i++) {
		let rep = "vars." + parameters[i].name;
		let regex = new RegExp(rep, "g");
		string = string.replace(regex, parameters[i].value);
	}
	return string;
};

const WeightCalculator: React.FC<WeightCalculatorProps> = ({ heroContent }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const defaultMaterial =
		WeightCalculatorData.WeightCalcData.Materials[language].Material[0].Name;
	const [material, setMaterial] = React.useState(defaultMaterial);
	const materials: Option[] = [];
	WeightCalculatorData.WeightCalcData.Materials[language].Material.forEach(
		(materialObject: any) => {
			materials.push({
				display: materialObject.Display,
				value: materialObject.Name,
			});
		}
	);
	const defaultAlloy =
		WeightCalculatorData.WeightCalcData.Materials[language].Material[0].Alloys.Alloy[0].Name;
	const [alloy, setAlloy] = React.useState(defaultAlloy);

	const defaultAlloys: Option[] = [];
	WeightCalculatorData.WeightCalcData.Materials[language].Material[0].Alloys.Alloy.forEach(
		(alloyItem: any) => {
			defaultAlloys.push({
				display: alloyItem.Display,
				value: alloyItem.Name,
			});
		}
	);
	const [alloys, setAlloys] = React.useState(defaultAlloys);
	const defaultShape = WeightCalculatorData.WeightCalcData.Shapes[language].Shape[0].Name;
	const [shape, setShape] = React.useState(defaultShape);
	const shapes: Option[] = [];
	WeightCalculatorData.WeightCalcData.Shapes[language].Shape.forEach((shapeObject: any) => {
		shapes.push({
			display: shapeObject.Display,
			value: shapeObject.Name,
		});
	});
	const defaultDimensionValues: any[] = [];
	WeightCalculatorData.WeightCalcData.Shapes[language].Shape[0].Parameters.Parameter.forEach(
		(parameterObject: any) => {
			defaultDimensionValues.push({
				label: parameterObject.Display,
				value: 0,
				uom: "in",
			});
		}
	);
	const [dimensionValues, setDimensionValues] = React.useState(defaultDimensionValues);

	const getInches = (uom: any, value: number) => {
		switch (uom) {
			case "ft":
				return value * 12;
			case "yd":
				return value * 36;
			case "mm":
				return value / 25.4;
			case "cm":
				return value / 2.54;
			case "m":
				return value * 39.3701;
			default:
				return value;
		}
	};

	const calculate = () => {
		WeightCalculatorData.WeightCalcData.Calculations.Calculation.forEach(
			(calculationObject: any) => {
				if (
					calculationObject.Material.indexOf(material) !== -1 &&
					calculationObject.Alloy.indexOf(alloy) !== -1 &&
					calculationObject.Shape.indexOf(shape) !== -1
				) {
					let formula = calculationObject.Formula;
					let vars: any[] = [];
					for (let i = 0; i < dimensionValues.length; i++) {
						vars.push({
							name: "var" + i,
							value: getInches(dimensionValues[i].uom, dimensionValues[i].value),
						});
					}
					let formulaString = EvaluateString(formula, vars);
					let weight = stringMath(formulaString);
					setPoundsPerPiece(weight);
				}
			}
		);
	};
	const reset = () => {
		setMaterial(defaultMaterial);
		setShape(defaultShape);
		setAlloy(defaultAlloy);
		setAlloys(defaultAlloys);
		setDimensionValues(defaultDimensionValues);
		setNumberOfPieces(1);
		setPoundsPerPiece(0);
	};
	React.useEffect(() => {
		let newDimensionValues = [...dimensionValues];
		newDimensionValues.length = 0;
		WeightCalculatorData.WeightCalcData.Shapes[language].Shape.forEach((shapeObject: any) => {
			if (shapeObject.Name === shape) {
				shapeObject.Parameters.Parameter.forEach((parameterObject: any) => {
					newDimensionValues.push({
						label: parameterObject.Display,
						value: 0,
						uom: "in",
					});
					setDimensionValues(newDimensionValues);
				});
			}
		});
	}, [shape]);
	const uomOptions: LanguageContent<Option[]> = {
		en: [
			{
				display: "in",
				value: "in",
			},
			{
				display: "ft",
				value: "ft",
			},
			{
				display: "yd",
				value: "yd",
			},
			{
				display: "mm",
				value: "mm",
			},
			{
				display: "cm",
				value: "cm",
			},
			{
				display: "m",
				value: "m",
			},
		],
		fr: [
			{
				display: "po",
				value: "in",
			},
			{
				display: "pi",
				value: "ft",
			},
			{
				display: "vg",
				value: "yd",
			},
			{
				display: "mm",
				value: "mm",
			},
			{
				display: "cm",
				value: "cm",
			},
			{
				display: "m",
				value: "m",
			},
		],
		es: [
			{
				display: "pulg.",
				value: "in",
			},
			{
				display: "pie",
				value: "ft",
			},
			{
				display: "yarda",
				value: "yd",
			},
			{
				display: "mm",
				value: "mm",
			},
			{
				display: "cm",
				value: "cm",
			},
			{
				display: "m",
				value: "m",
			},
		],
	};
	const dimensionValueChanged = (label: any, value: any, uom: any) => {
		const newDimensionValues = [...dimensionValues];

		for (let i = 0; i < newDimensionValues.length; i++) {
			if (label === newDimensionValues[i].label) {
				newDimensionValues[i] = {
					label: newDimensionValues[i].label,
					value: value,
					uom: uom,
				};
				setDimensionValues(newDimensionValues);
				break;
			}
		}
	};
	React.useEffect(() => {
		WeightCalculatorData.WeightCalcData.Materials[language].Material.forEach(
			(searchMaterial: any, index: number) => {
				if (searchMaterial.Name === material) {
					const alloyOptions: Option[] = [];
					searchMaterial.Alloys.Alloy.forEach((alloyItem: any) => {
						alloyOptions.push({
							display: alloyItem.Display,
							value: alloyItem.Name,
						});
					});
					setAlloys(JSON.parse(JSON.stringify(alloyOptions)));
				}
			}
		);
	}, [language]);
	React.useEffect(() => {
		setAlloy(alloys[0].value);
	}, [alloys]);
	React.useEffect(() => {
		WeightCalculatorData.WeightCalcData.Materials[language].Material.forEach(
			(searchMaterial: any, index: number) => {
				if (searchMaterial.Name === material) {
					const alloyOptions: Option[] = [];
					searchMaterial.Alloys.Alloy.forEach((alloyItem: any) => {
						alloyOptions.push({
							display: alloyItem.Display,
							value: alloyItem.Name,
						});
					});
					setAlloys(JSON.parse(JSON.stringify(alloyOptions)));
				}
			}
		);
	}, [material]);
	const [numberOfPieces, setNumberOfPieces] = React.useState(1);
	const [poundsPerPiece, setPoundsPerPiece] = React.useState(0);
	const formatNumber = (number: any) => {
		return parseFloat(number).toLocaleString();
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="0px">
					<CalculatorContainer
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<ImageContainer
							css={css`
								background-image: url(${ShapeImages[shape].image});
							`}
						/>
						<FormContainer
							css={css`
								background-color: ${theme.colors.secondary.background};
							`}
						>
							<FormRow>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
										size="md"
										type="secondary"
									>
										{StaticContent[language].product}
									</Typography>
									<Dropdown
										type="secondary"
										language={language}
										options={materials}
										value={material}
										onChange={(val) => {
											setMaterial(val);
										}}
									/>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
										size="md"
										type="secondary"
									>
										{StaticContent[language].shape}
									</Typography>
									<Dropdown
										language={language}
										type="secondary"
										options={shapes}
										value={shape}
										onChange={(val) => {
											setShape(val);
										}}
									/>
								</FormCell>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
										size="md"
										type="secondary"
									>
										{StaticContent[language].grade}
									</Typography>
									<Dropdown
										language={language}
										type="secondary"
										options={alloys}
										value={alloy}
										onChange={(val) => {
											setAlloy(val);
										}}
									/>
								</FormCell>
							</FormRow>
							<FormRow>
								{dimensionValues.map((dimensionObject: any, index: number) => {
									return (
										<FormCell key={index}>
											<Typography
												variant="div"
												css={css`
													margin-bottom: 9px;
													padding-top: 10px;
												`}
												type="secondary"
											>
												{
													StaticContent[language][
														dimensionObject.label
															.replace(" ", "")
															.toLowerCase() as keyof WeightCalculatorStaticContent
													]
												}
												<LabelIcon>
													<img
														src={
															ShapeImages[shape].dimensions[
																dimensionObject.label
															]
														}
													/>
												</LabelIcon>
											</Typography>
											<Flex
												direction="row"
												wrap="nowrap"
												justifyContent="space-between"
											>
												<FlexItem
													css={css`
														width: calc(100% - 94px);
													`}
												>
													<IncrementDecrementInput
														language={language}
														value={dimensionObject.value}
														onChange={(e: any) => {
															dimensionValueChanged(
																dimensionObject.label,
																e.target.value,
																dimensionObject.uom
															);
														}}
														onIncrement={() => {
															dimensionValueChanged(
																dimensionObject.label,
																parseFloat(dimensionObject.value) +
																	1,
																dimensionObject.uom
															);
														}}
														onDecrement={() => {
															if (
																parseFloat(dimensionObject.value) -
																	1 >
																0
															) {
																dimensionValueChanged(
																	dimensionObject.label,
																	parseFloat(
																		dimensionObject.value
																	) - 1,
																	dimensionObject.uom
																);
															}
														}}
													/>
												</FlexItem>
												<FlexItem
													css={css`
														width: 85px;
													`}
												>
													<Dropdown
														language={language}
														type="secondary"
														options={uomOptions[language]}
														value={dimensionObject.uom}
														onChange={(val) => {
															dimensionValueChanged(
																dimensionObject.label,
																dimensionObject.value,
																val
															);
														}}
													/>
												</FlexItem>
											</Flex>
										</FormCell>
									);
								})}
							</FormRow>
							<FormRow
								css={css`
									margin-bottom: 40px;
								`}
							>
								<FormCell>
									<Typography
										variant="div"
										css={css`
											margin-bottom: 9px;
										`}
									>
										{StaticContent[language].numberOfPieces}
									</Typography>
									<IncrementDecrementInput
										language={language}
										value={numberOfPieces}
										onChange={(e: any) => {
											setNumberOfPieces(e.target.value);
										}}
										onIncrement={() => {
											setNumberOfPieces(Number(numberOfPieces) + 1);
										}}
										onDecrement={() => {
											if (numberOfPieces - 1 > 0) {
												setNumberOfPieces(numberOfPieces - 1);
											}
										}}
									/>
								</FormCell>
							</FormRow>
							<FormRow
								css={css`
									margin-bottom: 0px;
								`}
							>
								<FormCell>
									<Button
										type="secondary"
										size="sm"
										label={StaticContent[language].calculate}
										fullwidth={true}
										shape="rounded"
										onClick={calculate}
									/>
								</FormCell>
								<FormCell
									css={css`
										max-width: 118px;
										min-width: 118px;
									`}
								>
									<Button
										background={theme.colors.primary.darkGray}
										foreground="light"
										size="sm"
										label={StaticContent[language].reset}
										fullwidth={true}
										shape="rounded"
										onClick={reset}
									/>
								</FormCell>
							</FormRow>
						</FormContainer>
					</CalculatorContainer>
					<ResultsContainer
						css={css`
							background-color: ${theme.colors.primary.darkGray};
						`}
					>
						<ResultsLeft>
							<Typography variant="div">
								<Typography
									variant="span"
									size="lg"
									color={theme.colors.primary.white}
								>
									{numberOfPieces +
										" " +
										StaticContent[language].pieces +
										" x " +
										formatNumber(poundsPerPiece.toFixed(2)) +
										" " +
										StaticContent[language].pounds +
										" = "}
									<Typography
										variant="h2"
										color={theme.colors.primary.white}
										css={css`
											display: inline-block;
											margin-left: 5px;
											margin-right: 0px;
											vertical-align: baseline;
											letter-spacing: -2px;
										`}
									>
										{formatNumber(
											(numberOfPieces * poundsPerPiece)
												.toFixed(2)
												.split(".")
												.shift()
										)}
									</Typography>
									{"." +
										(numberOfPieces * poundsPerPiece)
											.toFixed(2)
											.split(".")
											.pop() +
										" " +
										StaticContent[language].pounds}
								</Typography>
							</Typography>
							<Typography
								variant="div"
								color={theme.colors.primary.lightGray}
								size="xs"
							>
								{StaticContent[language].disclaimer}
							</Typography>
						</ResultsLeft>
						{/* <ResultsRight>
							<ButtonLink
								to="#"
								label={StaticContent[language].seeMatching}
								size="sm"
								linkType="internal"
								type="tertiary"
								orientation="right"
							/>
						</ResultsRight> */}
					</ResultsContainer>
					<Spacer />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="secondary" vPadding="17px">
					<MobileSpacer />
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
							size="md"
							type="secondary"
						>
							{StaticContent[language].product}
						</Typography>
						<Dropdown
							type="secondary"
							language={language}
							options={materials}
							value={material}
							onChange={(val) => {
								setMaterial(val);
							}}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
							size="md"
							type="secondary"
						>
							{StaticContent[language].shape}
						</Typography>
						<Dropdown
							language={language}
							type="secondary"
							options={shapes}
							value={shape}
							onChange={(val) => {
								setShape(val);
							}}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
							size="md"
							type="secondary"
						>
							{StaticContent[language].grade}
						</Typography>
						<Dropdown
							language={language}
							type="secondary"
							options={alloys}
							value={alloy}
							onChange={(val) => {
								setAlloy(val);
							}}
						/>
					</MobileFormCell>
				</ContentSection>
				<ImageContainer
					css={css`
                        background-image: url(${ShapeImages[shape].image});
                        width:100%;
                        height: 200px;
                        background-size:auto; calc( 100% - 70px );
                        display:block;
                    `}
				/>
				<ContentSection type="secondary" vPadding="17px">
					<MobileSpacer />
					{dimensionValues.map((dimensionObject: any, index: number) => {
						return (
							<MobileFormCell key={index}>
								<Typography
									variant="div"
									css={css`
										margin-bottom: 9px;
										padding-top: 10px;
									`}
									type="secondary"
								>
									{dimensionObject.label}
									<LabelIcon>
										<img
											src={
												ShapeImages[shape].dimensions[dimensionObject.label]
											}
										/>
									</LabelIcon>
								</Typography>
								<Flex direction="row" wrap="nowrap" justifyContent="space-between">
									<FlexItem
										css={css`
											width: calc(100% - 94px);
										`}
									>
										<IncrementDecrementInput
											language={language}
											value={dimensionObject.value}
											onChange={(e: any) => {
												dimensionValueChanged(
													dimensionObject.label,
													e.target.value,
													dimensionObject.uom
												);
											}}
											onIncrement={() => {
												dimensionValueChanged(
													dimensionObject.label,
													parseFloat(dimensionObject.value) + 1,
													dimensionObject.uom
												);
											}}
											onDecrement={() => {
												if (parseFloat(dimensionObject.value) - 1 > 0) {
													dimensionValueChanged(
														dimensionObject.label,
														parseFloat(dimensionObject.value) - 1,
														dimensionObject.uom
													);
												}
											}}
										/>
									</FlexItem>
									<FlexItem
										css={css`
											width: 85px;
										`}
									>
										<Dropdown
											language={language}
											type="secondary"
											options={uomOptions[language]}
											value={dimensionObject.uom}
											onChange={(val) => {
												dimensionValueChanged(
													dimensionObject.label,
													dimensionObject.value,
													val
												);
											}}
										/>
									</FlexItem>
								</Flex>
							</MobileFormCell>
						);
					})}
					<MobileFormCell>
						<Typography
							variant="div"
							css={css`
								margin-bottom: 9px;
							`}
						>
							{StaticContent[language].numberOfPieces}
						</Typography>
						<IncrementDecrementInput
							language={language}
							value={numberOfPieces}
							onChange={(e: any) => {
								setNumberOfPieces(e.target.value);
							}}
							onIncrement={() => {
								setNumberOfPieces(Number(numberOfPieces) + 1);
							}}
							onDecrement={() => {
								if (numberOfPieces - 1 > 0) {
									setNumberOfPieces(numberOfPieces - 1);
								}
							}}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Button
							type="secondary"
							size="sm"
							label={StaticContent[language].calculate}
							fullwidth={true}
							shape="rounded"
							onClick={calculate}
						/>
					</MobileFormCell>
					<MobileFormCell>
						<Button
							background={theme.colors.primary.darkGray}
							foreground="light"
							size="sm"
							label={StaticContent[language].reset}
							fullwidth={true}
							shape="rounded"
							onClick={reset}
						/>
					</MobileFormCell>
					<MobileSpacer />
					<MobileSpacer />
				</ContentSection>
				<ResultsContainer
					css={css`
						background-color: ${theme.colors.primary.darkGray};
						padding-top: 30px;
						padding-left: 20px;
						padding-right: 20px;
						padding-bottom: 30px;
					`}
				>
					<Typography variant="div">
						<Typography variant="div" size="lg" color={theme.colors.primary.white}>
							{numberOfPieces +
								" " +
								StaticContent[language].pieces +
								" x " +
								formatNumber(poundsPerPiece.toFixed(2)) +
								" " +
								StaticContent[language].pounds +
								" = "}
						</Typography>
						<Typography variant="span" size="lg" color={theme.colors.primary.white}>
							<Typography
								variant="h1"
								color={theme.colors.primary.white}
								css={css`
									display: inline-block;
									vertical-align: baseline;
									letter-spacing: -2px;
								`}
							>
								{formatNumber(
									(numberOfPieces * poundsPerPiece).toFixed(2).split(".").shift()
								)}
							</Typography>
							{"." +
								(numberOfPieces * poundsPerPiece).toFixed(2).split(".").pop() +
								" " +
								StaticContent[language].pounds}
						</Typography>
					</Typography>
					<Typography variant="div" color={theme.colors.primary.lightGray} size="xs">
						{StaticContent[language].disclaimer}
					</Typography>
					{/* <Divider
						css={css`
							color: ${theme.colors.primary.white};
						`}
					/> */}
					{/* <Typography
						variant="div"
						css={css`
							display: block;
							text-align: center;
						`}
					>
						<ButtonLink
							to="#"
							label={StaticContent[language].seeMatching}
							size="sm"
							linkType="internal"
							type="tertiary"
							orientation="right"
						/>
					</Typography> */}
				</ResultsContainer>
				<ContentSection type="primary" vPadding="25px">
					<Typography variant="div" type="primary" size="lg">
						<Typography variant="span" type="primary" size="lg">
							{heroContent.needHelp}{" "}
						</Typography>
						<Link type="primary" to={heroContent.contactUsUrl}>
							{heroContent.contactUs}
						</Link>
						<Typography variant="span" type="primary" size="lg">
							{" "}
							{heroContent.or}{" "}
						</Typography>
						<Span
							onClick={() => {
								if (window && (window as any).LiveChatWidget) {
									(window as any).LiveChatWidget.call("maximize");
								}
							}}
						>
							<Link type="primary" to={heroContent.liveChatUrl}>
								{heroContent.liveChat}
							</Link>
						</Span>
					</Typography>
					<MobileSpacer />
				</ContentSection>
			</Media>
		</>
	);
};

export default WeightCalculator;
